export const products = [
    {
      id: 1,
      image: '/images/bottle1.jpg',
      title: '2020 Chardonnay, Yarra Valley Chardonnay',
      price: 50.00
    },
    {
        id: 2,
        image: '/images/bottle2.jpg',
        title: '2023  Syrah / Pinot Noir, Yarra Valley Rosé',
        price: 32.00
      },
      {
        id: 3,
        image: '/images/bottle3.jpg',
        title: '2023 Pinot Noir, Yarra Valley Pinot Noir',
        price: 50.00
      },
    // Add more products as needed
  ];