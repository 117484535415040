import React from 'react';
import ProductList from './ProductList';

function App() {
  return (
    <div className="App">
      <h2
        class="relative lg:text-7xl text-3xl text-center font-bold font-display after:block after:bg-primary lg:after:h-3.5 after:h-2 lg:after:w-72 after:w-44 after:content-[''] after:m-auto after:mt-7 mb-10"
      >
        <span class="block font-fancy">Check out our </span>New Releases
      </h2>
      
      <ProductList />
      <button
            className="block mt-10 text-primary bg-white font-bold py-2 px-4 rounded-full border-primary capitalize hover:bg-secondary border-2 transition-colors duration-300 mx-auto"
          >
            See Our Wines
          </button>
      
    </div>
  );
}

export default App;