// src/components/Navigation.js
import React, { useState } from 'react';

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="relative">
      <button 
        onClick={toggleMenu} 
        className="lg:hidden absolute -top-7 md:-top-12 right-2 text-primary"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
        </svg>
      </button>

      <nav className={`${isMenuOpen ? 'block' : 'hidden'} lg:block`}>
        <ul className="flex flex-col lg:flex-row list-none w-full lg:w-auto mt-10 lg:mt-0">
          <li className="mx-5">
            <button className="text-primary py-3 px-4 rounded-md font-bold font-display hover:bg-secondary transition-colors duration-300 w-full lg:w-auto">Our Story</button>
          </li>
          <li className="mx-5">
            <button className="text-primary py-3 px-4 rounded-md font-bold font-display hover:bg-secondary transition-colors duration-300 w-full lg:w-auto">Wine / Shop</button>
          </li>
          <li className="mx-5">
            <button className="text-primary py-3 px-4 rounded-md font-bold font-display hover:bg-secondary transition-colors duration-300 w-full lg:w-auto">Vineyard</button>
          </li>
          <li className="mx-5">
            <button className="text-primary py-3 px-4 rounded-md font-bold font-display hover:bg-secondary transition-colors duration-300 w-full lg:w-auto">Visit</button>
          </li>
          <li className="mx-5">
            <button className="text-primary py-3 px-4 rounded-md font-bold font-display hover:bg-secondary transition-colors duration-300 w-full lg:w-auto">Wine Club</button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;