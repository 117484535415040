import React from 'react';
import Product from './Product';
import { products } from '../ProductsData';

function ProductList() {
  return (
    <div className="product-list lg:flex justify-between items-center mx-auto lg:w-2/3">
      {products.map((product) => (
        <Product
          key={product.id}
          image={product.image}
          title={product.title}
          price={product.price}
        />
      ))}
    </div>
  );
}

export default ProductList;