import React, { useEffect } from 'react';
import { initHeaderScroll } from '../headerScroll';
import Navigation from './Navigation';

function Header() {
  useEffect(() => {
    // Add the style
    const style = document.createElement('style');
    style.textContent = `
      header {
        transition: background-color 0.3s ease, padding 0.3s ease;
      }
      .logo {
        transition: transform 0.3s ease;
      }
    `;
    document.head.appendChild(style);

    // Initialize the scroll effect
    const cleanup = initHeaderScroll();

    // Cleanup function
    return () => {
      cleanup();
      document.head.removeChild(style);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <header className="lg:fixed z-20 w-full justify-center items-center p-5 md:px-10 md:py-7 font-display">
    <div className="lg:flex justify-between lg:m-5">
      <a href="#" className="block w-1/2"
        ><img src="/images/logo.svg" alt="Giant Steps" className="logo"
      /></a>

{/* Include the Navigation component */}
<Navigation />

    </div>
  </header>
  );
}


export default Header;