export function initHeaderScroll() {
  let header = document.querySelector('header');
  let logo = document.querySelector('.logo');
  let lastScrollTop = 0;

  function handleScroll() {
    if (!header || !logo) return; // Exit if elements don't exist

    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > lastScrollTop && scrollTop > 0) {
      // Scrolling down
      header.style.backgroundColor = 'white';
      header.style.padding = '0';
      logo.style.transform = 'scale(0.8)';
    } else if (scrollTop === 0) {
      // At the top of the page
      header.style.backgroundColor = '';
      header.style.padding = '';
      logo.style.transform = '';
    }

    lastScrollTop = scrollTop;
  }

  window.addEventListener('scroll', handleScroll);

  // Return a cleanup function
  return () => window.removeEventListener('scroll', handleScroll);
}