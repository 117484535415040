import React from 'react'

export default function GrungeDivider() {
  return (
    <div className='mx-auto h-11'>

        <img src="images/grungydivider.svg" alt=""
    />

    </div>
  )
}
