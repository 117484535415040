import React from 'react'

export default function Footer() {
  return (
    <footer className="mt-10">


      <div class="flex flex-wrap bg-[url('/public/images/bg-footer.png')] bg-footer-position bg-contain bg-no-repeat bg-primary px-11 py-24 text-secondary justify-between">

  <div class="w-full md:w-1/2 lg:w-1/3">
  <h4 className="font-bold text-2xl mb-7">Navigation</h4>
          <nav id="footer-nav">
            <ul className="font-sans uppercase text-sm">
              <li className="mb-2">
                <a href="#" className="underline hover:no-underline">Home</a>
              </li>
              <li className="mb-2">
                <a href="#" className="underline hover:no-underline">Our Story</a>
              </li>
              <li className="mb-2">
                <a href="#" className="underline hover:no-underline"
                  >Wines / Shop</a
                >
              </li>
              <li className="mb-2">
                <a href="#" className="underline hover:no-underline">Vineyards</a>
              </li>
              <li className="mb-2">
                <a href="#" className="underline hover:no-underline">Visit</a>
              </li>
              <li className="mb-2">
                <a href="#" className="underline hover:no-underline">Wine Club</a>
              </li>
              <li className="mb-2">
                <a href="#" className="underline hover:no-underline">Contact</a>
              </li>
            </ul>
          </nav>
  </div>
  

  <div class="w-full md:w-1/2 lg:w-1/3">
  <h4 className="font-bold text-2xl md:mb-7 md:mt-0  mt-16">Get in touch</h4>
          <p className="font-sans uppercase text-sm leading-10">
            Our cellar door is open daily from 11.00 am- 4.00 pm. <br />
            PHONE: +61 3 5962 6111
            <br />EMAIL:
            <a href="#" className="underline hover:no-underline"
              >mail@giantstepswine.com.au</a
            >
            <br />BOOKINGS DISTRIBUTION:
            <a href="#" className="underline hover:no-underline">AUST</a> /
            <a href="#" className="underline hover:no-underline">INTL</a>
          </p>
  </div>
  

  <div class="w-full lg:w-1/3">
  <h4 className="font-bold text-2xl mb-7 lg:mt-0  mt-16">Sign Up For Our Newsletter</h4>
          <form>
            <div className="form-group">
              <label for="exampleInputEmail1" className="hidden"
                >Email address</label
              >
              <input
                type="email"
                className="form-control w-full rounded-md bg-black bg-opacity-50 border border-secondary p-3 border-l-8 mb-3 focus:bg-opacity-90"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
              />
              <small id="emailHelp" className="form-text text-muted"></small>
            </div>
            <div className="form-group">
              <label for="exampleInputPassword1" className="hidden">Password</label>
              <input
                type="password"
                className="form-control w-full rounded-md bg-black bg-opacity-50 border border-secondary p-3 border-l-8 mb-3 focus:bg-opacity-90"
                id="exampleInputPassword1"
                placeholder="Password"
              />
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label ml-2 inline-block" for="exampleCheck1"
                >I wish to receive your promotions</label
              >
            </div>
            <button
              type="submit"
              className="block text-primary bg-secondary font-bold py-2 px-4 rounded-md border-primary capitalize hover:bg-white border-2 mt-4"
            >
              Submit
            </button>
          </form>
  </div>
  <div className="self-end relative md:absolute md:right-16">
          <img
            src="images/logo-white.svg"
            className="relative z-10 lg:top-52 w-52 lg:-mt-16 mt-10 mx-auto lg:mx-0"
          />
        </div>
</div>

      <div
        className="border-t border-secondary bg-primary py-16 lg:px-10 px-5 text-secondary font-sans"
      >
        <p className="uppercase text-xs text-center lg:text-left">
          copyright 2024 all rights reserved
          <span className="lg:inline-block mx-5 hidden">|</span>
          <span className='block mt-3 lg:inline'>website by <a
            href="#"
            className="underline hover:no-underline"
            title="Lawrence of Australia"
            >LoA</a
          ></span>
        </p>
      </div>
    </footer>
  )
}
