import React from 'react';
import Header from './components/Header'; 
import Hero from './components/Hero'; 
import ContentHome from './components/ContentHome';
import GrungeDivider from './components/GrungeDivider';
import Banner1 from './components/Banner1';
import NewReleases from './components/NewReleases';
import Footer from './components/Footer';



function App() {
  return (
    <div className="App">
      <Header /> {/* Use the Header component */}
      <Hero /> {/* Use the Hero component */}
      <ContentHome />
        {/* Your main content goes here */}
      <GrungeDivider /> {/* Your content goes here */}
      <Banner1 /> {/* Your banner content goes here */}
      <NewReleases /> {}
      <Footer /> {/* content */}
      
    </div>
  );
}

export default App;