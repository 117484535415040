function ContentHome() {
    return (
<main
      className="bg-darktan lg:px-32 relative font-display bg-[url('/public/images/bg-wineholder.png')] bg-no-repeat bg-left-bottom"
    >
      <div className="flex flex-col lg:flex-row">
        <div id="welcome1" className="lg:w-1/2 lg:pr-44 px-7">
          <h1 className="lg:text-6xl text-3xl font-display my-7">Welcome</h1>
          <p className="lg:mt-12 lg:text-2xl lg:leading-10">
            Since 2003 Giant Steps wines have collectively been awarded 34
            trophies and over 100 gold medals at major international and
            domestic wine shows and was named one of the Top 100 Wineries in the
            World by Wine & Spirits Magazine, US for each of the last six years.
          </p>
          <button
            className="block mt-10 text-primary bg-white font-bold py-2 px-4 rounded-full border-primary capitalize hover:bg-secondary border-2 transition-colors duration-300"
          >
            Read more
          </button>
        </div>

        <div
          id="welcome2"
          className="lg:w-1/2 md:w-full lg:pl-24 mt-14 border-spacing-10 border-primary items-center relative"
        >
          <h3
            className="lg:before:absolute lg:before:bg-primary lg:before:w-2 lg:before:h-96 lg:before:rounded-full lg:before:left-0 lg:before:mt-24"
          >
            <a href="#"
              ><img
                src="images/banner-book.svg"
                alt="Book a Tasting"
                className="lg:max-w-sm max-w-xs m-auto lg:mx-0 mb-10 transform transition-transform duration-300 ease-in-out hover:scale-110"
            /></a>
          </h3>
          <h3>
            <a href="#"
              ><img
                src="images/banner-see.svg"
                alt="See Our Vineyards"
                className="lg:max-w-sm max-w-xs m-auto lg:mx-0 mb-10 transform transition-transform duration-300 ease-in-out hover:scale-110"
            /></a>
          </h3>
          <h3>
            <a href="#"
              ><img
                src="images/banner-join.svg"
                alt="Join Our Wine Club"
                className="lg:max-w-sm max-w-xs m-auto lg:mx-0 mb-10 transform transition-transform duration-300 ease-in-out hover:scale-110"
            /></a>
          </h3>
        </div>
      </div>
      <div className="bg-primary h-2 w-full lg:mt-24 mt-7 rounded-full"></div>
      <grid className="lg:grid lg:grid-cols-3 lg:mt-24 gap-14 pb-20">
        <div className="md:px-28 lg:px-0">
          <img src="images/our-story.svg" className="min-w-full my-11 lg:mt-0 " />
          <h4
            className="relative ml-7 lg:ml-0 text-3xl font-bold after:inline-block after:bg-primary after:h-1 after:w-36 after:mt-12 after:left-0 after:absolute"
          >
            Our Story
          </h4>
          <p className="mt-12 pr-20 ml-7 lg:ml-0">
            Since 2003 Giant Steps wines have collectively been awarded 34
            trophies and over 100 gold medals at major international and
            domestic wine shows and has been named one of the Top 100 Wineries
            in the World by Wine & Spirits Magazine, US for each of the last six
            years.
          </p>
          <a
            href="#"
            title="Read more"
            className="flex items-end justify-end mt-7 mr-20 before:inline-block before:h-0.5 before:w-36 before:bg-primary before:relative before:-top-4 transform transition duration-300 ease-in-out hover:translate-x-5"
            ><img src="images/arrow1.svg" alt="Read more"
          /></a>
        </div>
        <div className="md:px-28 lg:px-0">
          <img src="images/the-wines.svg" className="min-w-full my-11 lg:mt-0" />
          <h4
            className="ml-7 lg:ml-0 relative text-3xl font-bold after:inline-block after:bg-primary after:h-1 after:w-36 after:mt-12 after:left-0 after:absolute"
          >
            The Wines
          </h4>
          <p className="mt-12 pr-20 ml-7 lg:ml-0">
            Since 2003 Giant Steps wines have collectively been awarded 34
            trophies and over 100 gold medals at major international and
            domestic wine shows and has been named one of the Top 100 Wineries
            in the World by Wine & Spirits Magazine, US for each of the last six
            years.
          </p>
          <a
            href="#"
            title="Read more"
            className="flex items-end justify-end mt-7 mr-20 before:inline-block before:h-0.5 before:w-36 before:bg-primary before:relative before:-top-4 transform transition duration-300 ease-in-out hover:translate-x-5"
            ><img src="images/arrow1.svg" alt="Read more"
          /></a>
        </div>
        <div className="md:px-28 lg:px-0">
          <img src="images/visit-us.svg" className="min-w-full my-11 lg:mt-0" />
          <h4
            className="ml-7 lg:ml-0 relative text-3xl font-bold after:inline-block after:bg-primary after:h-1 after:w-36 after:mt-12 after:left-0 after:absolute"
          >
            Visit us
          </h4>
          <p className="ml-7 lg:ml-0 mt-12 pr-20">
            Since 2003 Giant Steps wines have collectively been awarded 34
            trophies and over 100 gold medals at major international and
            domestic wine shows and has been named one of the Top 100 Wineries
            in the World by Wine & Spirits Magazine, US for each of the last six
            years.
          </p>
          <a
            href="#"
            title="Read more"
            className="flex items-end justify-end mt-7 mr-20 before:inline-block before:h-0.5 before:w-36 before:bg-primary before:relative before:-top-4 transform transition duration-300 ease-in-out hover:translate-x-5"
            ><img src="images/arrow1.svg" alt="Read more"
          /></a>
        </div>
      </grid>
    </main>

    );
}

export default ContentHome;