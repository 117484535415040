import React from 'react'

export default function Banner1() {
  return (

    <div
      id="banner"
      className="w-full mt-10 lg:mt-0"
    >
      <img
        src="images/banner-obsessed.png"
        alt="You could say we are obsessed with wine... We would agree!"
        className="mx-auto lg:mb-44 mb-14 z-10 relative"
      />
    </div>
    
  )
}

