import React from 'react';

function Product({ image, title, price }) {
  return (
    <div className="product ">
      <a href="#" className='flex flex-col justify-center items-center hover:shadow-lg hover:border hover:rounded-lg p-6 transition duration-300'><img src={image} alt={title} className="product-image max-w-full h-auto" />
      <h3 className="product-title text-primary uppercase tracking-tight font-semibold text-center after:content-center after:block after:mx-auto after:w-32 after:h-1 after:m-3.5 after:bg-primary">{title}</h3>
      <p className="product-price">${price.toFixed(2)}</p></a>
    </div>
  );
}

export default Product;