function Hero() {
    return (
    <div
      id="hero"
      class="flex flex-col items-start lg:min-h-[1149px] min-h-[640px] md:min-h-[870px] justify-center bg-[url('../public/images/bg-home-m.jpg')] lg:bg-[url('../public/images/bg-hero1.jpg')] md:bg-[url('../public/images/bg-hero-t.jpg')]"
    >
        
      <h2 class="lg:ml-24 mx-7 z-10 relative">
        <img
          src="images/tagline1.svg"
          alt="Because something is better than nothing"
          class="block"
        />
      </h2>
      <button
        class="block mt-10 ml-32 text-primary bg-secondary font-bold py-2 px-4 rounded-full border-primary capitalize hover:bg-white border-2"
      >
        Read more
      </button>
    </div>
    );
}

export default Hero;